import { IconButton, type IconButtonProps as MuiIconButtonProps } from 'components'
import { FC } from 'react'
import styles from './styles.module.scss'
import classNames from 'classnames/bind';
import Loader from 'components/Loader';

const classes = classNames.bind(styles);

interface IconButtonComponentProps extends MuiIconButtonProps {
    loading?: boolean
    buttonType?: 'with-background' | 'without-background' | 'primary' | 'white';
    buttonSize?: 'small' | 'medium';
}

const IconButtonComponent: FC<IconButtonComponentProps> = ({
    className,
    children,
    loading,
    buttonType = 'without-background',
    buttonSize = 'small',
    ...props
}) => {
    return (
        <IconButton
            {...props}
            className={classes('iconButton', className, buttonType, buttonSize)}
        >
            {children}
            {loading && <Loader size={10} />}
        </IconButton>
    )
}

export default IconButtonComponent