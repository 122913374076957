import { CommonButton, Flexbox, Typography } from 'components';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';
import { AIStarsIcon } from 'components/icons';
import Tag, { TagType } from '../Tag';

const classes = classNames.bind(styles);

interface InsightProps {
    title: string;
    percentage: string;
    description: string;
}

const Insight = ({ title, percentage, description }: InsightProps) => {
    return (
        <Flexbox className={classes('insight-container')}>
            <Flexbox vertical className={classes('insight-item')}>
                <Flexbox vertical className={classes('insight')}>
                    <Flexbox vertical className={classes('main-content', 'gap-1')}>
                        <Flexbox justifyBetween className={classes('gap-3')} style={{ alignItems: 'flex-start' }}>
                            <Flexbox align>
                                <Typography className={classes('main-content-title', 'paragraph-16-bold')}>{title}</Typography>
                            </Flexbox>
                            <Tag label={percentage} type={TagType.NEGATIVE} />
                        </Flexbox>
                        <Flexbox align>
                            <Typography className={classes('main-content-description', 'paragraph-15-readable-regular ')}>{description}</Typography>
                        </Flexbox>
                    </Flexbox>

                    <Flexbox align className={classes('action-buttons', 'gap-1')}>
                        <CommonButton buttonSize='medium' buttonType='black' className={classes('resolve')} startIcon={<AIStarsIcon />}>Resolve</CommonButton>
                        <CommonButton buttonSize='medium' buttonType='secondary'>Ignore</CommonButton>
                    </Flexbox>
                </Flexbox>
            </Flexbox>
        </Flexbox>
    );
};

export default Insight;