import { createApi } from '@reduxjs/toolkit/query/react';
import { emitInitiative } from 'utils/request';
import { rtkBaseQueryWithReauth } from 'utils/rtk';

export const initiativeApi = createApi({
    reducerPath: 'initiative.api',
    baseQuery: rtkBaseQueryWithReauth,
    tagTypes: ['initiative'],
    endpoints: builder => ({
        getInitiativeById: builder.query({
            query: ({ workspaceId, initiativeId }) => `/workspaces/${workspaceId}/initiatives/${initiativeId}`,
        }),
        getTeamResources: builder.query({
            query: ({ workspaceId, initiativeId, teamId }) => `/workspaces/${workspaceId}/initiatives/${initiativeId}/teams/${teamId}/resources`,
        }),
        createInitiative: builder.mutation({
            query: ({ workspaceId, initiative }) => {
                console.log(initiative, 'initiative');

                return {
                    url: `/workspaces/${workspaceId}/initiatives`,
                    method: 'POST',
                    body: initiative
                };
            },
        }),
        duplicateInitiative: builder.mutation({
            query: ({ workspaceId, initiativeId }) => {
                return {
                    url: `/workspaces/${workspaceId}/initiatives/${initiativeId}/duplicate`,
                    method: 'POST',
                };
            },
        }),
        addTeamResource: builder.mutation({
            query: ({ workspaceId, initiativeId, teamId, resource }) => {
                return {
                    url: `/workspaces/${workspaceId}/initiatives/${initiativeId}/teams/${teamId}/resources`,
                    method: 'POST',
                    body: resource
                };
            },
        }),
        editTeamResource: builder.mutation({
            query: ({ workspaceId, initiativeId, teamId, resourceId, resource }) => {
                return {
                    url: `/workspaces/${workspaceId}/initiatives/${initiativeId}/teams/${teamId}/resources/${resourceId}`,
                    method: 'PUT',
                    body: resource
                };
            },
        }),
        editInitiative: builder.mutation({
            query: ({ workspaceId, initiative, initiativeId }) => {
                return {
                    url: `/workspaces/${workspaceId}/initiatives/${initiativeId}`,
                    method: 'PUT',
                    body: initiative
                };
            },
        }),
        moveInitiativeToLive: builder.mutation({
            query: ({ workspaceId, initiativeId }) => {
                return {
                    url: `/workspaces/${workspaceId}/initiatives/${initiativeId}/move-to-live`,
                    method: 'PUT',
                };
            },
        }),
        generateInitiative: builder.mutation({
            queryFn: async ({ workspaceId, initiativeId, idea, knowledgeContext, onResponse, onError }, { getState }) => {
                const state = getState() as { user: { token: string } };

                return new Promise((resolve, reject) => {
                    emitInitiative('generate_prd',
                        {
                            initiativeId,
                            kb_context: knowledgeContext,
                            workspaceId: workspaceId,
                            Authorization: `Bearer ${state.user.token}`,
                            prompt: idea
                        },
                        (response) => {
                            onResponse(response);
                            resolve({ data: response });
                        },
                        (error) => {
                            onError(error);
                            reject({ error });
                        }
                    );
                });
            }
        }),
        generateRequirement: builder.mutation({
            queryFn: async ({ workspaceId, initiativeId, requirementId, idea, onResponse, onError, knowledgeContext }, { getState }) => {
                const state = getState() as { user: { token: string } };

                return new Promise((resolve, reject) => {
                    emitInitiative('generate_story',
                        {
                            initiativeId,
                            kb_context: knowledgeContext,
                            storyId: requirementId,
                            workspaceId: workspaceId,
                            Authorization: `Bearer ${state.user.token}`,
                            prompt: idea
                        },
                        (response) => {
                            onResponse(response);
                            resolve({ data: response });
                        },
                        (error) => {
                            onError(error);
                            reject({ error });
                        }
                    );
                });
            }
        }),
        deleteTeamResource: builder.mutation({
            query: ({ workspaceId, initiativeId, teamId, resourceId }) => {
                return {
                    url: `/workspaces/${workspaceId}/initiatives/${initiativeId}/teams/${teamId}/resources/${resourceId}`,
                    method: 'DELETE',
                };
            },
        }),
    })
});

export const {
    useAddTeamResourceMutation,
    useDeleteTeamResourceMutation,
    useDuplicateInitiativeMutation,
    useEditInitiativeMutation,
    useEditTeamResourceMutation,
    useGenerateInitiativeMutation,
    useGenerateRequirementMutation,
    useGetInitiativeByIdQuery,
    useGetTeamResourcesQuery,
    useLazyGetInitiativeByIdQuery,
    useLazyGetTeamResourcesQuery,
    useMoveInitiativeToLiveMutation,
    useCreateInitiativeMutation
} = initiativeApi