
import { Flexbox, Typography } from 'components'
import styles from './styles.module.scss'
import classNames from 'classnames/bind'
import { FC, useState } from 'react'
import { ContentType } from '..'
import ArrowDown from 'components/icons/ArrowDown'
import ArrowUp from 'components/icons/ArrowUp'
import OverviewCommonFields from '../OverviewCommonFields'

const classes = classNames.bind(styles)

interface OverviewProps {
    title: string,
    description?: string,
    content: { label: string, value?: string | Date | { link: string, text: string }, type: ContentType }[]
}

const Overview: FC<OverviewProps> = ({ title, description, content }) => {
    const [isAllFieldsOpen, setIsAllFieldsOpen] = useState(false);

    const handleToggleAllFields = () => {
        setIsAllFieldsOpen(!isAllFieldsOpen);
    }

    return (
        <Flexbox fullWidth vertical className={classes('overviewContainer', 'gap-3')} id='initiative-project-overview' data-section-id='project-overview'>
            <Flexbox vertical className={classes('gap-5')}>
                <Flexbox justifyBetween className={classes('header')}>
                    <Typography className={classes('title')}>{title}</Typography>
                    {description && <Typography className={classes('description', 'caption-2-regular')}>Last edited <span className={classes('description-value')}>{description}</span></Typography>}
                </Flexbox>
                <OverviewCommonFields content={content} />
            </Flexbox>

            <Flexbox vertical align justify className={classes('footer', 'cursor-pointer')} onClick={handleToggleAllFields}>
                <Typography className={classes('footer-text', 'caption-2-regular')}>{isAllFieldsOpen ? 'See Less' : 'See All Fields'}</Typography>
                {isAllFieldsOpen ? <ArrowUp /> : <ArrowDown />}
            </Flexbox>
        </Flexbox>
    )
}

export default Overview