import classNames from 'classnames/bind'
import styles from './styles.module.scss'
import generalStyles from '../styles.module.scss'
import { CommonButton, Flexbox, RichTextEditor, Typography } from 'components'
import { AIStarsIcon } from 'components/icons'
import { mockedSections } from '..'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { editModeSelector } from 'store/initiative-slice'

const classes = classNames.bind(styles)
const generalClasses = classNames.bind(generalStyles);

interface MainContentProps {
}

const MainContent: FC<MainContentProps> = () => {
    // @mocked sections
    const mockedSectionsFull = [
        {
            id: mockedSections[1].id,
            title: mockedSections[1].title,
            icon: mockedSections[1].icon,
            content: `New users frequently encounter obstacles during the sign-up process, which often requires filling out lengthy forms, creating strong passwords, and confirming various account details. These steps can feel cumbersome and time-consuming, leading to frustration and abandonment of the registration process. 

As a result, businesses face reduced user acquisition and lower conversion rates. Addressing these pain points is essential to improve the overall user experience and encourage more users to complete the onboarding process.

Implementing a streamlined and secure Google authentication solution can resolve these challenges effectively. By allowing users to sign up using their existing Google accounts, the process becomes faster, easier, and more intuitive. 

This eliminates the need for manual data entry, reducing friction and making it more likely that users will successfully complete the registration process. Furthermore, Google authentication enhances the perception of reliability and security, as users are more likely to trust a process backed by Google’s established reputation.

Additionally, the use of Google’s OAuth 2.0 protocol ensures that authentication is not only user-friendly but also highly secure. The system safeguards user data by securely managing access tokens and preventing unauthorized access. Adhering to these security standards not only protects user privacy but also builds trust, encouraging higher engagement and retention rates.

Key goals for this initiative include:

	•	Simplifying the sign-up process by integrating Google authentication, offering users a seamless and efficient onboarding experience.
	•	Increasing conversion rates by reducing the effort required to manually input data and complete the registration.
	•	Ensuring robust security and compliance with Google’s OAuth 2.0 guidelines, fostering trust and safeguarding user information.

By addressing these challenges and implementing this solution, the business can achieve higher user satisfaction, improved onboarding experiences, and a significant boost in user acquisition metrics.`
        },
        {
            id: mockedSections[2].id,
            title: mockedSections[2].title,
            icon: mockedSections[2].icon,
            content: `Ensure the “Sign Up with Google” button is prominently displayed on the sign-up screen, easily noticeable to users as a primary call-to-action option. The button should adhere strictly to Google’s branding guidelines, including correct usage of colors, typography, and spacing, to maintain consistency and build user trust. 

Additionally, ensure the button is responsive and displays correctly across different devices, screen resolutions, and orientations. The button should be functional and provide immediate feedback (e.g., animation or visual cues) when tapped, clearly indicating to users that their action has been registered. 

Proper accessibility features, such as appropriate ARIA labels and sufficient color contrast, should also be implemented to accommodate users with disabilities.

Test Cases:
	•	Verify the button is displayed prominently on the sign-up screen.
	•	Test button responsiveness (clickable state) across different devices and screen sizes.

	2.	Google Authentication Flow

	•	Validation: Confirm that tapping the button triggers Google’s OAuth 2.0 authentication process.
	•	Test Cases:
	•	Verify redirection to Google’s authentication page.
	•	Test the ability to select and authenticate with multiple Google accounts.
	•	Validate correct handling when a user denies permission or cancels the authentication.
	3.	Permission Handling
	•	Validation: Ensure that the app requests only necessary permissions (e.g., email, profile picture) and explains why they are needed.
	•	Test Cases:
	•	Verify that the permission prompt includes the required scopes.
	•	Test the flow when users grant permissions versus when they deny them.
	4.	User Data Retrieval
	•	Validation: Ensure accurate retrieval of the user’s basic profile data (e.g., name, email, profile picture).
	•	Test Cases:
	•	Verify correct population of user data fields after authentication.
	•	Test handling of incomplete profiles (e.g., missing email or profile picture).
	5.	Backend Token Verification
	•	Validation: Ensure the backend securely verifies the authenticity of the Google authentication token.
	•	Test Cases:
	•	Validate token authenticity using Google’s API.
	•	Test rejection of expired, invalid, or tampered tokens.
	6.	Error Handling
	•	Validation: Ensure user-friendly error messages are displayed for common failures.
	•	Test Cases:
	•	Verify error messages for network issues (e.g., “Check your internet connection”).
	•	Validate errors for permission denial (e.g., “Google sign-up requires permission to access your account”).
	•	Test fallback for failed token verification.`
        },
        {
            id: mockedSections[3].id,
            title: mockedSections[3].title,
            icon: mockedSections[3].icon,
            content: `Prominent Button Placement and Design

	•	Position the “Sign Up with Google” button at the top of the sign-up options or within a visually distinct section.

	•	Adhere strictly to Google’s branding guidelines for button design (colors, typography, spacing).

	•	Ensure clear labeling (e.g., “Sign Up with Google”) to communicate its purpose.
	•	Implement responsive design for consistent visibility across devices, resolutions, and orientations.

	2.	Responsive and Functional Button

	•	Use CSS/JS for responsiveness and ensure proper rendering across breakpoints.
	•	Add interactive feedback (e.g., hover effects, animations, loading indicators) for user interaction clarity.

	3.	Accessibility Features

	•	Add ARIA labels (e.g., aria-label="Sign Up with Google") for screen readers.
	•	Ensure color contrast meets WCAG standards for users with visual impairments.

	4.	Authentication Flow Integration

	•	Integrate Google OAuth 2.0 for seamless authentication.
	•	Redirect users to Google’s login page upon button interaction.
	•	Handle multiple account selection and cancellations gracefully.`
        }
    ]

    const editMode = useSelector(editModeSelector)

    return (
        <Flexbox vertical fullWidth className={classes('main-content-container')}>
            <Flexbox vertical className={classes('main-content')}>
                {mockedSectionsFull.map((section) => (
                    <Flexbox id={`initiative-${section.id}`} data-section-id={section.id} vertical key={section.id} className={classes('section')}>
                        <Flexbox align justifyBetween className={classes('section-heading')}>
                            <Flexbox align className={classes('gap-1')}>
                                <Flexbox>
                                    {section.icon}
                                </Flexbox>
                                <Typography className={classes('section-heading-title', 'body-bold')}>{section.title}</Typography>
                            </Flexbox>
                            <CommonButton buttonSize='small' buttonType='shadow' startIcon={<AIStarsIcon />}>Rewrite with AI</CommonButton>
                        </Flexbox>
                        <Flexbox vertical className={generalClasses('initiative-editor-container')}>
                            <RichTextEditor
                                placeholder={`${section.title} content`}
                                value={section.content}
                                onChange={value => { }}
                                disabled={!editMode}
                            />
                        </Flexbox>
                    </Flexbox>
                ))}
            </Flexbox>
        </Flexbox>
    )
}

export default MainContent