import { Flexbox } from 'components'
import ContentSectionHeader from './ContentSectionHeader'
import styles from './styles.module.scss'
import classNames from 'classnames/bind'
import { FC, forwardRef, ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { editModeSelector } from 'store/initiative-slice'

const classes = classNames.bind(styles)

interface ContentSectionLayoutProps {
    title: string
    icon?: ReactNode
    children: ReactNode
    buttonText?: string
    actions?: ReactNode
    id?: string
    className?: string
}

const ContentSectionLayout: FC<ContentSectionLayoutProps> = forwardRef(({ title, icon, children, buttonText, actions, id, className }, ref) => {
    const editMode = useSelector(editModeSelector);

    return (
        <Flexbox vertical fullWidth className={classes('content-section-layout', className)} id={id}>
            <ContentSectionHeader title={title} icon={icon} editMode={editMode} buttonText={buttonText} actions={actions} />
            <Flexbox vertical className={classes('content-section-body')}>
                {children}
            </Flexbox>
        </Flexbox>
    )
})

export default ContentSectionLayout