import { CommonButton, Flexbox, Typography } from 'components'
import styles from './styles.module.scss'
import classNames from 'classnames/bind'
import { FC, ReactNode } from 'react'
import { AIStarsIcon } from 'components/icons'

const classes = classNames.bind(styles)

interface ContentSectionHeaderProps {
    title: string
    icon?: ReactNode
    editMode: boolean
    onGenerate?: () => void
    buttonText?: string
    actions?: ReactNode
}

const ContentSectionHeader: FC<ContentSectionHeaderProps> = ({
    title,
    icon,
    buttonText,
    actions
}) => {
    return (
        <Flexbox justifyBetween align className={classes('content-section-header', 'gap-2')}>
            <Flexbox align className={classes('gap-2', 'title-container')}>
                {icon ? icon : ''}
                <Typography className={classes('title')}>{title}</Typography>
            </Flexbox>
            {actions ? actions : (
                <CommonButton buttonSize='small' buttonType='shadow' startIcon={<AIStarsIcon />}>{buttonText || 'Rewrite with AI'}</CommonButton>
            )}
        </Flexbox>
    )
}

export default ContentSectionHeader