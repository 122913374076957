import { Flexbox, Typography } from 'components';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { BoltIcon, PlusIcon, } from 'components/icons';
import MessageBox from './MessageBox';
import SidePanelInput from './SidePanelInput';
import { useState } from 'react';

const classes = classNames.bind(styles);

const ChatAssistant = () => {
    const [question, setQuestion] = useState('');

    const handleQuestionChange = (value: string) => {
        setQuestion(value);
    }

    return (
        <Flexbox vertical className={classes('chat-assistant')}>
            <Flexbox vertical justifyEnd className={classes('chat-assistant-body')}>
                <MessageBox actionType='assignTeams' description='Would you like to assign teams?' />
                <MessageBox actionType='changeStatus' description='It seems like you have done lots of progress in here would you like to change status of this initiative?' />
                <MessageBox actionType='okrInteraction' description='You want to ask something about the OKR, what do you want to do exactly?' />
            </Flexbox>

            <Flexbox vertical className={classes('chat-assistant-footer')}>
                <Flexbox vertical className={classes('header')}>
                    <Flexbox align className={classes('gap-1', 'add-context')}>
                        <Flexbox align justify className={classes('header-icon')}>
                            <PlusIcon />
                        </Flexbox>
                        <Typography className={classes('header-title', 'caption-2-regular')}>
                            Add Context
                        </Typography>
                    </Flexbox>
                </Flexbox>

                <SidePanelInput
                    placeholder='Ask a question...'
                    value={question}
                    onChange={handleQuestionChange}
                    description='Sometimes we might generate wrong answers'
                    buttonIcon={<BoltIcon />}
                />
            </Flexbox>
        </Flexbox>
    )
}

export default ChatAssistant;