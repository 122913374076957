import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Initiative, InitiativeStatus } from 'utils/types'
import { RootState } from 'store';
import { SaveStatus } from 'common/savingStatus';

interface InitiativeState {
    currentInitiative: Initiative,
    savingStatus: SaveStatus,
    editMode: boolean,
    insightsOpen: boolean,
}

const initialState: InitiativeState = {
    currentInitiative: {
        title: 'Default Title',
    } as Initiative,
    savingStatus: SaveStatus.UNSET,
    editMode: false,
    insightsOpen: false,
}

const initiativeSlice = createSlice({
    name: 'initiative',
    initialState,
    reducers: {
        changeTitle: (state, action: PayloadAction<string>) => {
            state.currentInitiative.title = action.payload
        },
        setSavingStatus: (state, action: PayloadAction<SaveStatus>) => {
            state.savingStatus = action.payload;
        },
        setStatus: (state, action: PayloadAction<InitiativeStatus>) => {
            state.currentInitiative.status = action.payload;
        },
        setEditMode: (state, action: PayloadAction<boolean>) => {
            state.editMode = action.payload;
        },
        setInsightsOpen: (state, action: PayloadAction<boolean>) => {
            state.insightsOpen = action.payload;
        },
    },
    extraReducers: (builder) => {
        // builder.addMatcher(
        //     initiativeApi.endpoints.getInitiativeById.matchFulfilled,
        //     (state, action: PayloadAction<Initiative>) => {
        //         state.currentInitiative = action.payload;
        //     },
        // )
    },
})

// export const initiativeSelector = (store: RootState) => store.initiative.currentInitiative;
// export const initiativeOwnerSelector = (store: RootState) => store.initiative.currentInitiative.owner;
// export const savingStatusSelector = (store: RootState) => store.initiative.savingStatus;
// export const selectedProductsSelector = (store: RootState) => store.initiative.currentInitiative.products;
// export const statusSelector = (store: RootState) => store.initiative.currentInitiative.status;
// export const prioritySelector = (store: RootState) => store.initiative.currentInitiative.priority;
// export const sizeSelector = (store: RootState) => store.initiative.currentInitiative.size;
// export const typeSelector = (store: RootState) => store.initiative.currentInitiative.type;
// export const problemSelector = (store: RootState) => store.initiative.currentInitiative.problem;
// export const solutionSelector = (store: RootState) => store.initiative.currentInitiative.solution;
// export const validationSelector = (store: RootState) => store.initiative.currentInitiative.validation;
// export const startDateSelector = (store: RootState) => store.initiative.currentInitiative.startDate;
// export const endDateSelector = (store: RootState) => store.initiative.currentInitiative.endDate;
// export const releaseDateSelector = (store: RootState) => store.initiative.currentInitiative.releaseDate;
// export const affectedProductsSelector = (store: RootState) => store.initiative.currentInitiative.affectedProducts;
// export const teamsSelector = (store: RootState) => store.initiative.currentInitiative.teams;
// export const blocksSelector = (store: RootState) => store.initiative.currentInitiative.blocks;
// export const okrsSelector = (store: RootState) => store.initiative.currentInitiative.okrs;
// export const successDefinitionSelector = (store: RootState) => store.initiative.currentInitiative.successDefinition;

export const initiativeTitleSelector = (store: RootState) => store.initiative.currentInitiative.title;
export const editModeSelector = (store: RootState) => store.initiative.editMode;
export const insightsOpenSelector = (store: RootState) => store.initiative.insightsOpen;

export const {
    changeTitle,
    setStatus,
    setSavingStatus,
    setEditMode,
    setInsightsOpen,
} = initiativeSlice.actions

export default initiativeSlice;