import { CommonButton, Flexbox, Typography } from 'components';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { AIStarsIcon, InitiativeEmptyStateIcon } from 'components/icons';

const classes = classNames.bind(styles);

interface EmptyViewProps {
    title: string;
    description: string;
    actionText: string;
    secondaryAction: string;
}

const EmptyView = ({ title, description, actionText, secondaryAction }: EmptyViewProps) => {
    return (
        <Flexbox vertical justify align className={classes('empty-view')}>
            <Flexbox vertical justify align className={classes('empty-view-content')}>
                <InitiativeEmptyStateIcon />
                <Flexbox vertical justify align className={classes('gap-6')}>
                    <Flexbox vertical justify align className={classes('gap-2')}>
                        <Typography className={classes('title', 'paragraph-16-regular')}>{title}</Typography>
                        <Typography className={classes('description', 'paragraph-15-regular')}>{description}</Typography>
                    </Flexbox>
                    <Flexbox justifyBetween align vertical className={classes('gap-4')}>
                        <CommonButton buttonType='shadow' buttonSize='small' startIcon={<AIStarsIcon />}>{actionText}</CommonButton>
                        {secondaryAction && (
                            <Flexbox vertical justify align className={classes('manual-action')}>
                                <Typography className={classes('manual-action-text', 'paragraph-15-regular')}>{secondaryAction}</Typography>
                            </Flexbox>
                        )}
                    </Flexbox>
                </Flexbox>
            </Flexbox>
        </Flexbox>
    )
}

export default EmptyView;