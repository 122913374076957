import { PropsWithChildren, useEffect } from 'react';
import { Flexbox, NotFound } from 'components';
import Sidebar from './Sidebar';
import Content from './Content';
import { ACTION_UPDATE_NOT_FOUND, notFoundSelector } from 'store/globalError';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import TableOfContents from 'pages/InitiativeComponents/TableOfContents';

const MainLayout = (props: PropsWithChildren<any>) => {

    const dispatch = useDispatch();
    const notFound = useSelector(notFoundSelector);
    const location = useLocation();

    useEffect(() => {
        dispatch({ type: ACTION_UPDATE_NOT_FOUND, payload: false });
    }, [location])

    const isInitiativeEditor = location.pathname.includes('/initiativeBeta');

    return (
        <Flexbox vertical
            // TODO: remove/change this after the initiative editor is implemented
            style={{ background: isInitiativeEditor ? 'rgba(249, 250, 250, 1)' : 'transparent' }}
        >
            <Flexbox>
                {isInitiativeEditor ? <TableOfContents /> : <Sidebar />}
                <Content>
                    {notFound ? <NotFound /> : props.children}
                </Content>
            </Flexbox>
        </Flexbox>
    )
}

export default MainLayout