import { Flexbox, Typography, IconButtonComponent, CommonButton } from 'components';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { AIStarsIcon, SidebarIcon } from 'components/icons';
import Insight from './Insight';
import { useDispatch } from 'react-redux';
import { setInsightsOpen } from 'store/initiative-slice';
import { FC } from 'react';

const classes = classNames.bind(styles);

const insights = [
    { title: 'Incomplete Problem that are from the previous tasks', percentage: '-12%', description: 'Incomplete Problem that are from the previous tasks' },
    { title: 'Incomplete Problem that are from the previous tasks', percentage: '-12%', description: 'Incomplete Problem that are from the previous tasks' },
    { title: 'Incomplete Problem that are from the previous tasks', percentage: '-12%', description: 'Incomplete Problem that are from the previous tasks' },
    { title: 'Incomplete Problem that are from the previous tasks', percentage: '-12%', description: 'Incomplete Problem that are from the previous tasks' },
    { title: 'Incomplete Problem that are from the previous tasks', percentage: '-12%', description: 'Incomplete Problem that are from the previous tasks' },
];

const ScoreSidePanel: FC = () => {
    // @mocked
    const score = 31;

    const dispatch = useDispatch();

    const handleCloseInsights = () => {
        dispatch(setInsightsOpen(false));
    }

    return (
        <Flexbox vertical className={classes('initiative-score-sidePanel')} id='initiative-sidePanel-insights'>
            <Flexbox vertical className={classes('header', 'gap-1')}>
                <Flexbox justifyBetween align className={classes('header-main')}>
                    <Flexbox align justify className='gap-2'>
                        <Flexbox align justify className={classes('header-main-icon')}>
                            <AIStarsIcon />
                        </Flexbox>
                        <Typography className={classes('header-main-title', 'body-bold')}>Insights</Typography>
                    </Flexbox>
                    <IconButtonComponent onClick={handleCloseInsights}><SidebarIcon /></IconButtonComponent>
                </Flexbox>

                <Flexbox vertical className={classes('header-sub')}>
                    <Flexbox vertical className={classes('score-details')}>
                        <Flexbox justifyBetween align className={classes('gap-2')}>
                            <Flexbox className={classes('score-details-text')}>
                                <span className={classes('paragraph-15-regular')}>Quality Score at&nbsp;</span>
                                <span className={classes('paragraph-15-bold')}>{`${score}%`}</span>
                            </Flexbox>
                            <CommonButton buttonSize='small' buttonType='shadow' startIcon={<AIStarsIcon />}>Resolve All</CommonButton>
                        </Flexbox>
                        <div className={classes('progress-bar-container')}>
                            <div className={classes('progress-bar-fill')} style={{ width: '31%' }}></div>
                        </div>
                    </Flexbox>
                    <Flexbox className={classes('score-description')}>
                        <Typography className={classes('score-description-text', 'caption-2-regular')}>4 Unfinished section to be completed.</Typography>
                    </Flexbox>
                </Flexbox>
            </Flexbox>

            <Flexbox vertical justifyEnd fullWidth className={classes('gap-2', 'content-container')}>
                <Flexbox vertical className={classes('content')}>
                    {insights.map((insight, index) => (
                        <Insight key={index} title={insight.title} percentage={insight.percentage} description={insight.description} />
                    ))}
                </Flexbox>

                <Flexbox align justify className={classes('footer')}>
                    <Typography className={classes('footer-text', 'paragraph-15-readable-regular')}>prodmap.ai</Typography>
                </Flexbox>
            </Flexbox>
        </Flexbox>
    );
};

export default ScoreSidePanel;