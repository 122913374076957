import { CommonButton, Flexbox, Typography } from 'components'
import styles from './styles.module.scss'
import classNames from 'classnames/bind'
import IconButtonComponent from 'components/IconButtonComponent'
import { ArrowLeftIcon } from 'components/icons'
import { PenIcon } from 'components/icons'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeTitle, editModeSelector, initiativeTitleSelector, setEditMode } from 'store/initiative-slice'

const classes = classNames.bind(styles)

interface HeadingProps {

}

const Heading: FC<HeadingProps> = ({ }) => {
    const dispatch = useDispatch();

    const title = useSelector(initiativeTitleSelector);
    const editMode = useSelector(editModeSelector);

    const handleChangeMode = () => {
        dispatch(setEditMode(!editMode));
    }

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(changeTitle(e.target.value));
    }

    return (
        <Flexbox vertical fullWidth className={classes('heading-container')} id='initiative-heading'>
            <Flexbox fullWidth align justifyBetween className={classes('heading')}>
                <Flexbox fullWidth className={classes('heading-left')}>
                    <IconButtonComponent onClick={() => { }} buttonType='with-background' buttonSize='medium'>
                        <ArrowLeftIcon />
                    </IconButtonComponent>
                    {
                        editMode ? (
                            <Flexbox className={classes('title-editor', 'edit', 'gap-1')}>
                                <Flexbox align className={classes('title-editor-input-container')}>
                                    <input type='text' value={title} className={classes('title-editor-input')} onChange={handleTitleChange} />
                                </Flexbox>
                                <PenIcon />
                            </Flexbox>
                        ) :
                            <Flexbox align className={classes('title-editor')}>
                                <Typography className={classes('title-editor-text')}>{title}</Typography>
                            </Flexbox>
                    }
                </Flexbox>

                <CommonButton buttonSize='medium' buttonType={editMode ? 'action' : 'shadow'} onClick={handleChangeMode}>
                    {editMode ? 'Save' : 'Edit'}
                </CommonButton>
            </Flexbox>
        </Flexbox>
    )
}

export default Heading