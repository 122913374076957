import { Flexbox, Typography } from 'components'
import ContentSectionLayout from '../ContentSectionLayout'
import styles from './styles.module.scss'
import { PaintBrushBroadIcon } from 'components/icons'
import classNames from 'classnames/bind'
import { ChangeEvent, FC, useState } from 'react'
import CustomTabs from 'components/CustomTabs'
import CustomTab from 'components/CustomTabs/CustomTab'

const classes = classNames.bind(styles)

interface MockupsProps {
}

const Mockups: FC<MockupsProps> = () => {
    const [activeTab, setActiveTab] = useState<'design' | 'code'>('design');

    return (
        <ContentSectionLayout
            title="Mockups"
            icon={<PaintBrushBroadIcon />}
            buttonText={'Generate With AI'}
            id='initiative-mockups'
        >
            <Flexbox vertical className={classes('mockups-container')} data-section-id='mockups'>
                <Flexbox align justifyBetween className={classes('mockups-container-header')}>
                    <Typography className={classes('section-title', 'caption-2-medium')}>Login Page</Typography>
                    <CustomTabs
                        value={activeTab}
                        onChange={(_event: ChangeEvent<{}>, value: 'design' | 'code') => setActiveTab(value)}
                    >
                        <CustomTab key='design' value='design' label='Design' />
                        <CustomTab key='code' value='code' label='Code' />
                    </CustomTabs>
                </Flexbox>
                <Flexbox vertical align justify className={classes('mockups-container-body')}>
                    Mockuppppp
                </Flexbox>
            </Flexbox>
        </ContentSectionLayout>
    )
}

export default Mockups