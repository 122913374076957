import { Flexbox } from 'components'
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { ProdmapIconBase } from 'components/icons';
import TextWithAction from './ChatElements/TextWithAction';

const classes = classNames.bind(styles);

const MessageBox = ({ actionType, description }: { actionType: 'assignTeams' | 'changeStatus' | 'okrInteraction', description: string }) => {
    return (
        <Flexbox justifyBetween className={classes('message-box')}>
            <Flexbox align justify className={classes('message-box-icon')}>
                <ProdmapIconBase />
            </Flexbox>

            <Flexbox vertical align justify className={classes('message-content')}>
                <TextWithAction
                    description={description}
                    actionType={actionType}
                />
            </Flexbox>
        </Flexbox>
    )
}

export default MessageBox;