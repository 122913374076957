import { CommonButton, Flexbox, Typography } from 'components'
import styles from './styles.module.scss'
import classNames from 'classnames/bind'
import { FC } from 'react'
import { formatDate } from 'utils/date'
import { ContentType } from '..'
import Tag, { TagType } from '../Tag'
import Selector from '../Selector'
import { BoltIcon, CustomLinkIcon, UserCircleIcon } from 'components/icons'
import SearchableSelect from 'components/SearchableSelect'
import { useSelector } from 'react-redux'
import { editModeSelector } from 'store/initiative-slice'

const classes = classNames.bind(styles)

interface OverviewCommonFieldsProps {
    content: { label: string, value?: any, type: ContentType }[]
}

const OverviewCommonFields: FC<OverviewCommonFieldsProps> = ({ content }) => {
    const editMode = useSelector(editModeSelector);

    return (
        <Flexbox vertical justify className={classes('overview-common-fields', 'gap-3')}>
            {content.map((item, index) => (
                <Flexbox key={index} justifyBetween align className={classes('body-item', 'gap-3')}>
                    <Typography className={classes('body-item-label', 'paragraph-readable-regular')}>{item.label}</Typography>
                    {item.type === ContentType.TEXT && (
                        <Selector label={item.value as string} type='non-editable' />
                    )}
                    {item.type === ContentType.START_DATE && (
                        <Selector label={formatDate(item.value as Date, 'MMM dd, yyyy')} type='non-editable' />
                    )}
                    {item.type === ContentType.LIVE_DATE && (
                        <Tag label={formatDate(item.value as Date, 'MMM dd, yyyy')} type={TagType.DONE} startIcon={<BoltIcon />} />
                    )}
                    {item.type === ContentType.DEVELOPMENT && (
                        editMode ?
                            <SearchableSelect
                                options={[
                                    { label: 'Development', value: '1' },
                                    { label: 'In Progress', value: '2' },
                                    { label: 'Done', value: '3' },
                                    { label: 'Cancelled', value: '4' },
                                ]}
                                value={item.value ? { label: item.value, value: '1' } : null}
                                onChange={(newValue) => console.log('Selected owner:', newValue)}
                                title={'Development Status'}
                                type={ContentType.TAG}
                            /> : <Tag label={item.value as string} type={TagType.IN_PROGRESS} />
                    )}
                    {item.type === ContentType.LINK && (
                        <CommonButton buttonType='shadow' buttonSize='small' startIcon={<CustomLinkIcon />}>
                            {item.value?.text as string}
                        </CommonButton>
                    )}
                    {item.type === ContentType.OWNER && (
                        editMode ?
                            <SearchableSelect
                                options={[
                                    { label: 'John Doe', value: '1' },
                                    { label: 'Jane Smith', value: '2' },
                                    { label: 'Mike Johnson', value: '3' },
                                    { label: 'Sarah Williams', value: '4' },
                                    { label: 'Mike Johnson', value: '5' },
                                    { label: 'Sarah Williams', value: '6' }
                                ]}
                                value={item.value ? { label: item.value.name, value: item.value.id } : null}
                                onChange={(newValue) => console.log('Selected owner:', newValue)}
                                label="Owner"
                                placeholder="Set Owner"
                                searchable={true}
                                labelStartIcon={<UserCircleIcon />}
                            /> : <Selector label={item.value as string || 'No Owner Yet'} type='non-editable' />
                    )}
                </Flexbox>
            ))
            }
        </Flexbox >
    )
}

export default OverviewCommonFields