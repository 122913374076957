import { Flexbox, IconButtonComponent, TextField } from 'components';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { ArrowRightLineIcon } from 'components/icons';

const classes = classNames.bind(styles);

interface InputProps {
    value: string;
    onChange: (value: string) => void;
    placeholder: string;
    buttonIcon?: React.ReactNode;
}

const Input = ({ value, onChange, placeholder, buttonIcon }: InputProps) => {
    return (
        <Flexbox justifyBetween align fullWidth className={classes('input-container')}>

            <TextField multiline maxRows={4} className={classes('input')} value={value} onChange={(event) => onChange(event.target.value)} placeholder={placeholder} />
            <IconButtonComponent buttonSize='small' buttonType='primary' className={classes('arrow-right-icon-button')}>
                <ArrowRightLineIcon />
            </IconButtonComponent>
        </Flexbox>
    )
}

export default Input;