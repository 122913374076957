import { CommonButton, Flexbox, Typography } from 'components'
import styles from './styles.module.scss'
import classNames from 'classnames/bind'
import { FC, useState, useRef } from 'react'
import ContentSectionLayout from '../ContentSectionLayout'
import { ArrowSquareOutIcon, CustomDeleteIcon, DownloadSimpleIcon, FileText, FileUploadIcon, LanguageIcon } from 'components/icons'
import { formatDate } from 'utils/date'
import IconButtonComponent from 'components/IconButtonComponent'
import { useSelector } from 'react-redux'
import { editModeSelector } from 'store/initiative-slice';
import CustomBackdrop from 'components/CustomBackdrop'
import Input from '../Input/Input'
const classes = classNames.bind(styles)

interface ResourcesProps {
}

// Mocked data
const files = [
    {
        name: 'user-findings.pdf',
        size: '2.4 MB',
        uploadedBy: 'Sarah Chen',
        uploadedDate: new Date('3/10/2024'),
        id: '1'
    },
    {
        name: 'latestreports02024.xlsx',
        size: '2.4 MB',
        uploadedBy: 'Sarah Chen',
        uploadedDate: new Date('3/10/2024'),
        id: '2'
    }
]

const links = [
    {
        name: 'Market research report',
        addedBy: 'Sarah Chen',
        addedDate: new Date('3/10/2024'),
        id: '3'
    },

]

const Resources: FC<ResourcesProps> = () => {
    const editMode = useSelector(editModeSelector);

    const resourcesSectionRef = useRef<HTMLDivElement>(null);

    const [addResourceOpen, setAddResourceOpen] = useState<{ open: boolean, type?: 'link' | 'file' }>({ open: false, type: 'link' });

    const handleAddResource = (type: 'link' | 'file') => {
        setAddResourceOpen({ open: !addResourceOpen.open, type });
    }

    return (
        <ContentSectionLayout
            title="Resources"
            icon={<FileText />}
            buttonText={'Ask AI'}
            id='initiative-resources'
            className={classes('resources-wrapper-container')}
            actions={
                editMode && (
                    <Flexbox align className={classes('actions-container', 'gap-1')} data-section-id='initiative-resources'>
                        {!addResourceOpen.open ? (
                            <>
                                <CommonButton buttonType='secondary' buttonSize='small' onClick={() => handleAddResource('link')}>
                                    Add Link
                                </CommonButton>
                                <CommonButton buttonType='shadow' buttonSize='small' onClick={() => handleAddResource('file')}>
                                    Add New File
                                </CommonButton>
                            </>
                        ) : (
                            <CommonButton buttonType='secondary' buttonSize='small' onClick={() => setAddResourceOpen({ open: false })}>
                                Cancel
                            </CommonButton>
                        )}
                    </Flexbox>
                )
            }
        >
            <Flexbox ref={resourcesSectionRef} vertical className={classes('resources-container', 'gap-4')}>
                {addResourceOpen.open && (
                    <>
                        <CustomBackdrop />
                        <Flexbox vertical justify className={classes('add-resource-container')} onClick={(e) => e.stopPropagation()}>
                            {addResourceOpen.type === 'link' && (
                                <>
                                    <Flexbox vertical className={classes('add-resource-container-content', 'gap-2')}>
                                        <Typography className={classes('add-resource-container-title', 'caption-2-medium')}>Title</Typography>
                                        <Input
                                            placeholder='Add Your Title'
                                            value={''}
                                            onChange={() => { }}
                                            className={'title-input'}
                                            width={358}
                                        />
                                    </Flexbox>
                                    <Flexbox vertical className={classes('add-resource-container-content', 'gap-2')}>
                                        <Typography className={classes('add-resource-container-title', 'caption-2-medium')}>Link</Typography>
                                        <Input
                                            leftIcon={<LanguageIcon />}
                                            value={''}
                                            onChange={() => { }}
                                            buttonText='Add'
                                            placeholder='Insert Your link here'
                                            buttonType='shadow'
                                            className={'link-input'}
                                            width={'100%'}
                                        />
                                    </Flexbox>
                                </>
                            )}
                            {addResourceOpen.type === 'file' && (
                                <Flexbox vertical align justify className={classes('add-resource-file-container', 'gap-4')}>
                                    <FileUploadIcon />
                                    <Flexbox vertical className={classes('add-resource-file-container-content', 'gap-2')}>
                                        <Typography className={classes('paragraph-16-regular')}>Upload Your File</Typography>
                                        <Typography className={classes('paragraph-15-regular',)}>Add images, PDFs, Designs or any additional resources here.</Typography>
                                    </Flexbox>
                                    <CommonButton
                                        buttonType='secondary'
                                        buttonSize='medium'
                                        onClick={() => { }}
                                        onFileSelect={(files) => {
                                            // Handle the selected files here
                                        }}
                                        accept=".pdf,.doc,.docx"
                                        multiple={true}
                                    >
                                        Choose File
                                    </CommonButton>
                                </Flexbox>

                            )}
                        </Flexbox>
                    </>
                )}

                {files.length > 0 && (
                    // TODO: We will make this a common component
                    <Flexbox vertical className={classes('section-container', 'gap-2')}>
                        <Flexbox>
                            <Typography className={classes('section-title', 'caption-2-medium')}>Files</Typography>
                        </Flexbox>

                        <Flexbox vertical className={classes('section-items-container', 'gap-1')}>
                            {files.map((file) => (
                                <Flexbox justifyBetween align key={file.id} className={classes('section-item-container')}>
                                    <Flexbox vertical className={classes('section-item-content-container', 'gap-2')}>
                                        <Typography className={classes('section-item-title')}>{file.name}</Typography>
                                        <Typography className={classes('paragraph-15-regular', 'section-item-description')}>
                                            {file.size} • Uploaded by {file.uploadedBy} • {formatDate(file.uploadedDate, 'MMM dd, yyyy')}
                                        </Typography>
                                    </Flexbox>
                                    <Flexbox align className={classes('actions-container', 'gap-1')}>
                                        {editMode && (
                                            <IconButtonComponent buttonType='with-background' buttonSize='medium'>
                                                <CustomDeleteIcon />
                                            </IconButtonComponent>
                                        )}
                                        <IconButtonComponent buttonType='with-background' buttonSize='medium'>
                                            <DownloadSimpleIcon />
                                        </IconButtonComponent>
                                    </Flexbox>
                                </Flexbox>
                            ))}
                        </Flexbox>
                    </Flexbox>
                )}

                {links.length > 0 && (
                    <Flexbox vertical className={classes('section-container', 'gap-2')}>
                        <Flexbox>
                            <Typography className={classes('section-title', 'caption-2-medium')}>Links</Typography>
                        </Flexbox>

                        <Flexbox vertical className={classes('section-items-container', 'gap-1')}>
                            {links.map((link) => (
                                <Flexbox justifyBetween align key={link.id} className={classes('section-item-container')}>
                                    <Flexbox vertical className={classes('section-item-content-container', 'gap-2')}>
                                        <Typography className={classes('section-item-title')}>{link.name}</Typography>
                                        <Typography className={classes('paragraph-15-regular', 'section-item-description')}>
                                            Added by {link.addedBy} • {formatDate(link.addedDate, 'MMM dd, yyyy')}
                                        </Typography>
                                    </Flexbox>
                                    <Flexbox align className={classes('actions-container', 'gap-1')}>
                                        {editMode && (
                                            <IconButtonComponent buttonType='with-background' buttonSize='medium'>
                                                <CustomDeleteIcon />
                                            </IconButtonComponent>
                                        )}
                                        <IconButtonComponent buttonType='with-background' buttonSize='medium'>
                                            <ArrowSquareOutIcon />
                                        </IconButtonComponent>
                                    </Flexbox>
                                </Flexbox>
                            ))}
                        </Flexbox>
                    </Flexbox>
                )}
            </Flexbox>
        </ContentSectionLayout>
    )
}

export default Resources