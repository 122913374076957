const Text = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clip-path="url(#clip0_8261_11127)">
                <path d="M9.99951 4.37463V15.6246" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M3.74951 6.87463V4.37463H16.2495V6.87463" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7.49951 15.6246H12.4995" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_8261_11127">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Text;