import { FC } from 'react'
import ContentSectionLayout from '../ContentSectionLayout'
import { ChatCenteredDotsIcon } from 'components/icons'
import { type Requirement as RequirementType } from '..'
import { Flexbox } from 'components'
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import Requirement from './Requirement'
import EmptyView from '../EmptyView'

const classes = classNames.bind(styles);

interface RequirementsProps {
    requirements: RequirementType[]
}

const Requirements: FC<RequirementsProps> = ({ requirements }) => {
    return (
        // TODO: Don't have an action if there are no requirements
        <ContentSectionLayout
            title="Requirements"
            icon={<ChatCenteredDotsIcon />}
            buttonText={'Generate With AI'}
            id='initiative-requirements'
        >
            {/* <Flexbox vertical className={classes('requirements-container', 'gap-1')} data-section-id='requirements'>
                {requirements.length > 0 && requirements.map((requirement, index) => (
                    <Requirement key={index} requirement={requirement} expanded={index === 0} />
                ))}
            </Flexbox> */}
            <EmptyView
                title='Add Requirements'
                description='List the essential features, functionalities, and constraints needed for the solution.'
                actionText='Add Requirements'
                secondaryAction="Add Manually"
            />
        </ContentSectionLayout>
    )
}

export default Requirements;
