import { TextField, Select, MenuItem, InputAdornment } from '@mui/material';
import { useState, useRef, ChangeEvent } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import Selector from '../../pages/InitiativeComponents/Selector';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { Flexbox, Typography } from 'components';
import { ContentType } from 'pages/InitiativeComponents';
import Tag, { TagType } from 'pages/InitiativeComponents/Tag';

const classes = classNames.bind(styles);

interface Option {
    label: string;
    value: string | number;
}

interface SearchableSelectProps {
    options: Option[];
    value?: Option | null;
    onChange?: (value: Option | null) => void;
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    error?: boolean;
    helperText?: string;
    required?: boolean;
    className?: string;
    searchable?: boolean;
    labelStartIcon?: React.ReactNode;
    title?: string;
    // peopleSelector, selector, tag
    type?: ContentType;
}

const SearchableSelect = ({
    options,
    value,
    onChange,
    label,
    placeholder = 'Select...',
    disabled = false,
    error = false,
    helperText,
    required = false,
    className,
    labelStartIcon,
    searchable,
    title,
    type
}: SearchableSelectProps) => {
    const [searchText, setSearchText] = useState('');

    const searchInputRef = useRef<HTMLInputElement>(null);

    const filteredOptions = options.filter(option =>
        option.label.toLowerCase().includes(searchText.toLowerCase())
    );

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    return (
        <Flexbox vertical className={classes('searchableSelectContainer')}>
            <Select
                value={value?.value || ''}
                onChange={(event) => {
                    const selectedOption = options.find(opt => opt.value === event.target.value);
                    onChange?.(selectedOption || null);
                }}
                className={classes('searchableSelect', className)}
                disabled={disabled}
                error={error}
                required={required}
                displayEmpty
                renderValue={(selected) => (
                    <Selector
                        label={value?.label || placeholder}
                        type={selected ? 'active' : 'default'}
                    />
                )}
                MenuProps={{
                    className: classes('searchableSelect-menu'),
                    PaperProps: {
                        className: classes('searchableSelect-menuPaper'),
                    },
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                }}
            >
                <Flexbox vertical className={classes('searchableSelect-menu')}>
                    {searchable && <TextField
                        className={classes('searchableSelect-searchInput')}
                        inputRef={searchInputRef}
                        placeholder="Search..."
                        value={searchText}
                        onChange={handleSearchChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        onClick={(e) => e.stopPropagation()}
                    />}

                    {title && <Flexbox vertical className={classes('p-3')}>
                        <Typography variant='body2' className={classes('searchableSelect-menuItem-label', 'caption-2-regular')}>{title}</Typography>
                    </Flexbox>}

                    <Flexbox vertical className={classes('searchableSelect-menuItems')}>
                        {filteredOptions.length === 0 ? (
                            <MenuItem disabled>No options found</MenuItem>
                        ) : (
                            filteredOptions.map((option) => (
                                <MenuItem
                                    key={option.value}
                                    value={option.value}
                                    className={classes('searchableSelect-menuItem')}
                                >
                                    {type === ContentType.TAG ? <Tag label={option.label} type={TagType.IN_PROGRESS} /> :
                                        <Flexbox align justify className={classes('gap-1')}>
                                            {labelStartIcon && <Flexbox>{labelStartIcon}</Flexbox>}
                                            <Typography variant='body2' className={classes('searchableSelect-menuItem-label', 'caption-2-regular')}>{option.label}</Typography>
                                        </Flexbox>
                                    }
                                </MenuItem>
                            ))
                        )}
                    </Flexbox>
                </Flexbox>
            </Select>
        </Flexbox>
    );
};

export default SearchableSelect;