import { Flexbox, Typography } from 'components'
import styles from './styles.module.scss'
import classNames from 'classnames/bind'
import { FC } from 'react'

const classes = classNames.bind(styles)

interface SelectorProps {
    label: string
    type?: 'active' | 'non-editable' | 'default'
    startIcon?: string
}

const Selector: FC<SelectorProps> = ({
    label = 'Selector',
    type = 'default',
    startIcon = null,
}) => {
    return (
        <Flexbox justify align className={classes('selector-container', type)}>
            {startIcon && <Flexbox justify align className={classes('start-icon')}>{startIcon}</Flexbox>}
            <Typography className={classes('selector-text', 'paragraph-15-regular')}>{label}</Typography>
        </Flexbox>
    )
}

export default Selector