import { Flexbox } from 'components'
import Overview from './Overview';
import Resources from './Resources';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import Requirements from './Requirements';
import Heading from './Heading';
import { useEffect } from 'react';
import MainContent from './MainContent';
import AssistantPanel from './AssistantPanel';
import {
    BroadcastIcon,
    BugIcon,
    CircleWavyCheckIcon,
    CheckCircleIconCustom,
    ChatCenteredDotsIcon,
    PaintBrushBroadIcon,
    FileText,
    Crosshair
} from 'components/icons';
import Mockups from './Mockups';
import OkrImpact from './OkrImpact';
import ScoreSidePanel from './ScoreSidePanel';
import { useSelector } from 'react-redux';
import { editModeSelector, insightsOpenSelector } from 'store/initiative-slice';

const classes = classNames.bind(styles)

// TODO: We will move this to a common place
export enum Mode {
    EDIT = 'edit',
    VIEW = 'view'
}

export enum ContentType {
    OWNER = 'owner',
    LINK = 'link',
    START_DATE = 'startDate',
    LIVE_DATE = 'liveDate',
    DEVELOPMENT = 'development',
    TEXT = 'text',
    TAG = 'tag',
    DATE = 'date'
}

export interface Requirement {
    title: string;
    description: string;
    owner: string;
    link: { link: string; text: string };
    dueDate: Date;
    development: string;
    priority: string;
    attachments: string[];
}

// Mocked data
const mockedOverviewProps = {
    title: 'Enhanced Multi-Canvas Support for Seamless Workflow Integration.',
    description: '2 hours ago',
    content: [
        { label: 'Owner', value: '', type: ContentType.OWNER },
        { label: 'Link', value: { link: 'https://www.google.com', text: 'Google' }, type: ContentType.LINK },
        { label: 'Start Date', value: new Date(), type: ContentType.DATE },
        { label: 'Live Date', value: new Date(), type: ContentType.LIVE_DATE },
        { label: 'Development', value: 'Development', type: ContentType.DEVELOPMENT },
        { label: 'Type', value: 'Feature', type: ContentType.TEXT },
        { label: 'Size', value: 'Large', type: ContentType.TEXT }
    ]
}

const mockedRequirementsProps = {
    mode: Mode.EDIT,
    requirements: [
        {
            title: 'User Authentication System',
            description: `
            The User Authentication System ensures secure access to the application by verifying user identities. It includes features such as account registration, login, password management, and multi-factor authentication (MFA). 

            The system uses encryption for data protection, supports third-party authentication providers (e.g., Google, Facebook), and adheres to industry standards for security and privacy. It also handles user roles and permissions to regulate access to specific features or content.

            Acceptance Criteria

            Support Google and Apple sign-in
            Implement email/password authentication
            Add password reset functionality
            Enable email verification
            Implement session management
            `,
            owner: 'John Doe',
            link: { link: 'https://www.google.com', text: 'Google' },
            dueDate: new Date(),
            development: 'In Progress',
            priority: 'High',
            attachments: []
        },
        {
            title: 'Building security System',
            description: `
            The Building Security System enhances the security of the building by monitoring and controlling access points. It includes features such as biometric access control, key card systems, and video surveillance.

            The system uses encryption for data protection, supports third-party authentication providers (e.g., Google, Facebook), and adheres to industry standards for security and privacy. It also handles user roles and permissions to regulate access to specific features or content.
            `,
            owner: 'John Doe',
            link: { link: 'https://www.google.com', text: 'Google' },
            dueDate: new Date(),
            development: 'In Progress',
            priority: 'High',
            attachments: []
        },
    ]
}

// @mocked sections
export const mockedSections = [
    { title: 'Project Overview', id: 'project-overview', icon: <BroadcastIcon /> },
    { title: 'Problem Statement', id: 'problem-statement', icon: <BugIcon /> },
    { title: 'Validation', id: 'validation', icon: <CheckCircleIconCustom /> },
    { title: 'High Level Solution', id: 'high-level-solution', icon: <CircleWavyCheckIcon /> },
    { title: 'OKR Impact', id: 'okr-impact', icon: <Crosshair /> },
    { title: 'Requirements', id: 'requirements', icon: <ChatCenteredDotsIcon /> },
    { title: 'Mockups', id: 'mockups', icon: <PaintBrushBroadIcon /> },
    { title: 'Resources', id: 'resources', icon: <FileText /> }
]

// TODO: Change the component name to InitiativeManagement
const InitiativeComponents = () => {
    const editMode = useSelector(editModeSelector);
    const insightsOpen = useSelector(insightsOpenSelector);

    useEffect(() => {
        const getSizes = () => {
            const sidebarElement = document.getElementById('initiative-sidePanel');
            const sidebarInsightsElement = document.getElementById('initiative-sidePanel-insights');
            const headingElement = document.getElementById('initiative-heading');

            if (sidebarElement) {
                document.documentElement.style.setProperty('--initiative-sidebar-width', `${sidebarElement.clientWidth + (sidebarInsightsElement ? (sidebarInsightsElement.clientWidth + 8) : 0)}px`);
            }

            if (headingElement) {
                document.documentElement.style.setProperty('--initiative-heading-width', `${headingElement.clientWidth}px`);
            }
        };

        // Initial measurement
        getSizes();

        // Create observer to watch for size changes
        const resizeObserver = new ResizeObserver(getSizes);
        const sidebarElement = document.getElementById('initiative-sidePanel');
        const headingElement = document.getElementById('initiative-heading');
        const sidebarInsightsElement = document.getElementById('initiative-sidePanel-insights');

        if (sidebarElement) { resizeObserver.observe(sidebarElement); }
        if (headingElement) { resizeObserver.observe(headingElement); }
        if (sidebarInsightsElement) { resizeObserver.observe(sidebarInsightsElement); }

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    return (
        <>
            {insightsOpen && <ScoreSidePanel />}
            <Flexbox fullWidth className={classes('initiative-management')}>
                <Flexbox vertical fullWidth className={classes('initiative-management-content')}>
                    <Heading />
                    <Flexbox justify fullWidth id='initiative-management' className={classes('initiative-management-content-main-container', { edit: editMode })}>
                        <Flexbox vertical fullWidth align className={classes('initiative-management-content-main')}>
                            <Overview {...mockedOverviewProps} />
                            <MainContent />
                            <OkrImpact />
                            <Requirements {...mockedRequirementsProps} />
                            <Mockups />
                            <Resources />
                        </Flexbox>
                    </Flexbox>
                </Flexbox>
                <Flexbox vertical fullWidth className={classes('initiative-management-assistant-panel')}>
                    <AssistantPanel />
                </Flexbox>
            </Flexbox>
        </>
    )
}

export default InitiativeComponents