import { CommonButton, Flexbox, Typography } from 'components';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import Tag, { TagType } from 'pages/InitiativeComponents/Tag';
import SearchableSelect from 'components/SearchableSelect';
import { UserCircleIcon } from 'components/icons';

const classes = classNames.bind(styles);

interface TextWithActionProps {
    description: string;
    actionType: 'assignTeams' | 'changeStatus' | 'okrInteraction';
}

const TextWithAction = ({ description, actionType }: TextWithActionProps) => {
    return (
        <Flexbox vertical className={classes('text-with-action')}>
            <Flexbox vertical className={classes('header', 'gap-2')}>
                {actionType === 'assignTeams' && <Tag label='Assign Teams' type={TagType.IN_PROGRESS} />}
                {actionType === 'changeStatus' && <Tag label='Change Status' type={TagType.DONE} />}
                <Typography className={classes('title', 'paragraph-15-readable-regular')}>
                    {description}
                </Typography>
            </Flexbox>

            {
                (actionType === 'assignTeams' || actionType === 'changeStatus') &&
                <SearchableSelect
                    options={[
                        { label: 'John Doe', value: '1' },
                        { label: 'Jane Smith', value: '2' },
                        { label: 'Mike Johnson', value: '3' },
                        { label: 'Sarah Williams', value: '4' },
                        { label: 'Mike Johnson', value: '5' },
                        { label: 'Sarah Williams', value: '6' }
                    ]}
                    value={null}
                    onChange={(newValue) => console.log('Selected owner:', newValue)}
                    label={actionType === 'assignTeams' ? 'Assign Teams' : actionType === 'changeStatus' ? 'Change Status' : 'OKR Interaction'}
                    placeholder={actionType === 'assignTeams' ? 'Assign Teams' : actionType === 'changeStatus' ? 'Change Status' : 'OKR Interaction'}
                    searchable={true}
                    labelStartIcon={<UserCircleIcon />}
                />
            }

            {actionType === 'okrInteraction' && (
                <Flexbox align className={classes('gap-1')}>
                    <CommonButton buttonType='shadow' buttonSize='small'>Suggest OKRs</CommonButton>
                    <CommonButton buttonType='shadow' buttonSize='small'>Match OKRs</CommonButton>
                </Flexbox>
            )}
        </Flexbox>
    )
}

export default TextWithAction;