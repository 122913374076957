import { FC } from 'react'
import ContentSectionLayout from '../ContentSectionLayout'
import { Crosshair, CustomDeleteIcon } from 'components/icons'
import { Checkbox, CommonButton, Flexbox, IconButtonComponent, TextField, Typography } from 'components'
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useSelector } from 'react-redux'
import { editModeSelector } from 'store/initiative-slice'

const classes = classNames.bind(styles);

interface OkrImpactProps {
}

const columns = ['Key Results', 'Current', 'Target', 'Initiative Impact'];
const rows = [
    { keyResult: 'Revenue', current: '$ 500,000', target: '$ 1,000,000', initiativeImpact: '$ 1,000,000', checked: true },
    { keyResult: 'Revenue', current: '$ 500,000', target: '$ 1,000,000', initiativeImpact: '$ 1,000,000', checked: false },
    { keyResult: 'Revenue', current: '$ 500,000', target: '$ 1,000,000', initiativeImpact: '$ 1,000,000', checked: false },
];

const OkrImpact: FC<OkrImpactProps> = () => {
    const editMode = useSelector(editModeSelector);

    return (
        <ContentSectionLayout title="Okr Impact" icon={<Crosshair />} buttonText={'Match OKR'} id='initiative-okr-impact'>
            <Flexbox vertical className={classes('okr-impact-container')} data-section-id='okr-impact'>
                <Flexbox justifyBetween align className={classes('okr-impact-container-header')}>
                    <Typography className={classes('title')}>Increase User Engagement and Retention</Typography>
                    {editMode && (
                        <IconButtonComponent buttonSize='small' buttonType='with-background'><CustomDeleteIcon /></IconButtonComponent>
                    )}
                </Flexbox>
                <TableContainer component={Paper} className={classes('okr-impact-container-table')}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell key={column}>{column}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.keyResult}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        <Flexbox justifyBetween align>
                                            {editMode && (
                                                <Checkbox
                                                    className={classes('checkbox')}
                                                    size='small'
                                                    checked={row.checked}
                                                />
                                            )}
                                            {row.keyResult}
                                        </Flexbox>
                                    </TableCell>
                                    <TableCell>{row.current}</TableCell>
                                    <TableCell>{row.target}</TableCell>
                                    {editMode ? (
                                        <TableCell>
                                            <TextField className={classes('text-field')} value={row.initiativeImpact} />
                                        </TableCell>
                                    ) : (
                                        <TableCell>{row.initiativeImpact}</TableCell>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {editMode && (
                    <CommonButton buttonType='shadow' buttonSize='medium'>
                        Choose OKR
                    </CommonButton>
                )}
            </Flexbox>
        </ContentSectionLayout>
    )
}

export default OkrImpact;