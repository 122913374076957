
import { Flexbox, IconButtonComponent } from 'components';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import Input from './Input';

const classes = classNames.bind(styles);

interface SidePanelInputProps {
    placeholder: string;
    value: string;
    onChange: (value: string) => void;
    description?: string;
    buttonIcon?: React.ReactNode;
}

const SidePanelInput = ({
    placeholder,
    value,
    onChange,
    description,
    buttonIcon,
}: SidePanelInputProps) => {
    return (
        <Flexbox vertical className={classes('sidePanel-input')}>
            <Flexbox align justify fullWidth className={classes('gap-1')}>
                {buttonIcon && (
                    <IconButtonComponent buttonSize='medium' buttonType='white' className={classes('left-icon-button')}>
                        {buttonIcon}
                    </IconButtonComponent>
                )}
                <Input value={value} onChange={onChange} placeholder={placeholder} buttonIcon={buttonIcon} />
            </Flexbox>
            {description && <Flexbox align justify className={classes('description')}>{description}</Flexbox>}
        </Flexbox>
    )
}

export default SidePanelInput;