import { Flexbox, Typography } from 'components'
import styles from './styles.module.scss'
import classNames from 'classnames/bind'
import { FC } from 'react'

const classes = classNames.bind(styles)

export enum TagType {
    NEGATIVE = 'negative',
    READY = 'ready',
    PAUSED = 'paused',
    IN_PROGRESS = 'inProgress',
    DONE = 'done',
    TESTING = 'testing',
    NATURAL = 'natural',
    WHITE = 'white',
    CAUTION = 'caution'
}

interface TagProps {
    label: string
    type?: TagType
    startIcon?: React.ReactNode
    endIcon?: React.ReactNode
}

const Tag: FC<TagProps> = ({
    label = 'Tag',
    type = TagType.WHITE,
    startIcon = null,
    endIcon = null
}) => {
    return (
        <Flexbox justify align className={classes('tagContainer', type)}>
            {startIcon && <Flexbox justify align className={classes('start-icon')}>{startIcon}</Flexbox>}
            <Typography className={classes('tag-text', 'caption-2-regular')}>{label}</Typography>
            {endIcon && <Flexbox justify align className={classes('end-icon')}>{endIcon}</Flexbox>}
        </Flexbox>
    )
}

export default Tag